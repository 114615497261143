import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ShareInfomationPageTemplate from "../templates/share-infomation-page";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  featuredimage,
  date,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <section className="l-section-lv2 l-main-media-hero">
        {helmet || ""}
        <div className="l-main-media-hero__inner">
          <div className="c-keyvisual-card">
            <div className="c-keyvisual-card__inner">
              <div className="c-keyvisual-card__title">
                <p className="c-keyvisual-card__title__sub-text">{date}</p>
                <h2 className="c-keyvisual-card__title__main-text">{title}</h2>
              </div>

              <div className="c-keyvisual-card__image">
                {featuredimage ? (
                  // <img src={featuredimage.childImageSharp.fluid.src} alt="" />
                  <img
                    src={
                      !!featuredimage.childImageSharp
                        ? featuredimage.childImageSharp.fluid.src
                        : featuredimage
                    }
                    alt=""
                  />
                ) : null}
                {/* <PostContent content={content} /> */}
              </div>

              <div className="c-keyvisual-card__description">
                <PostContent content={content} />
              </div>
            </div>
          </div>

          {tags && tags.length ? (
            <div style={{ marginTop: "4rem" }}>
              <h4 className="u-pc-fz18 u-sp-fz15 u-pc-mb10 u-sp-mb10 u-fw-b">
                タグ
              </h4>
              <div className="c-news-media__label-tile">
                {tags.map(tag => (
                  <Link
                    className="c-news-media__label-tile__item"
                    to={`/tags/${kebabCase(tag)}/`}
                    key={tag + "tag"}
                  >
                    <div className="c-rounded-label">
                      <p className="c-rounded-label__text">#{tag}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </section>

      {/* <section className="section">
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <p>{description}</p>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <Link to={"/blog/"} className="c-bread__link">
              <div className="c-bread__text">ブログ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">{`${post.frontmatter.title}`}</div>
          </div>
        </div>
      </div>

      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | ブログ">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        date={post.frontmatter.date}
      />

      <ShareInfomationPageTemplate />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
